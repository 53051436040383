<div class="reg_container">
    <div class="Page_container">
        <div class="reg_content">
            <div class="title_content">
                <h2>Así usamos las cookies.</h2>
            </div>
            <div class="columns">
                <div class="forms_container">
                    <div class="cookie_container">
                        <h2>¿Qué es una cookie?</h2>
                        <p class="sep-paragraph">
                          Cookie es el nombre que recibe un fichero de almacenamiento temporal de
                          información que usan las páginas de Internet. Estas cookies son enviadas por
                          páginas web y son almacenadas y administradas por los navegadores de Internet.
                        </p>
                        <h3>Uso de cookies en la web de Confia</h3>
                        <p>
                          En nuestra web utilizamos cookies propias y de empresas colaboradoras para
                          mejorar tu experiencia de usuario y mostrarte la publicidad adaptada a tus
                          hábitos de navegación.
                        </p>
                        <br>
                        <p>
                          Si navegas por nuestro sitio web, estarás aceptando el
                          uso de las cookies en las condiciones establecidas en la presente Política de
                          Cookies. Esta política puede ser actualizada, por lo que te invitamos a
                          revisarla de forma regular. 
                        </p>
                          <br>
                          <p class="sep-paragraph">
                            NOTA IMPORTANTE: las cookies que catalogamos como
                            Propias son imprescindibles para poder navegar con normalidad por nuestro
                            sitio web.
                          </p>
                        <h3>¿Qué tipos de cookies utiliza la web de Confia?</h3>
                        <h4>Cookies propias</h4>
                        <table mat-table [dataSource]="dataSource" class="tableMaterialCookies sep-table">
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef> Tipo de cookie </th>
                                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="info">
                                <th mat-header-cell *matHeaderCellDef> 	Información que recoge </th>
                                <td mat-cell *matCellDef="let element"> {{element.info}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="use">
                                <th mat-header-cell *matHeaderCellDef> Uso de la cookie </th>
                                <td mat-cell *matCellDef="let element"> {{element.use}} </td>
                            </ng-container>
                    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <h4>Cookies de terceros</h4>
                        <table mat-table [dataSource]="dataSourceTwo" class="tableMaterialCookies sep-table">
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef> Tipo de cookie </th>
                                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="business">
                                <th mat-header-cell *matHeaderCellDef> Empresa </th>
                                <td mat-cell *matCellDef="let element"> {{element.business}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="info">
                                <th mat-header-cell *matHeaderCellDef> 	Información que recoge </th>
                                <td mat-cell *matCellDef="let element"> {{element.info}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="use">
                                <th mat-header-cell *matHeaderCellDef> Uso de la cookie </th>
                                <td mat-cell *matCellDef="let element"> {{element.use}} </td>
                            </ng-container>
                    
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo;"></tr>
                        </table>
                        
                        <h3>Enlaces a redes sociales</h3>
                        <p class="sep-paragraph">
                          En el sitio web de Confia también se incluyen botones que los usuarios pueden
                          utilizar, de forma sencilla e intuitiva, para enlazar y compartir ciertos
                          contenidos de la web con redes sociales como Twitter o Facebook. Esta
                          funcionalidad conlleva el uso de cookies que enlazan con las páginas web de
                          estas redes sociales, almacenando la información únicamente en ellas.
                        </p>
                        <h3>Configuración de las cookies</h3>
                        <p>
                          Los navegadores más populares tienen opciones de configuración y
                          funcionalidades de seguridad que te permiten evitar la instalación de cookies
                          y/o de otros sistemas de recogida de datos de navegación en tu dispositivo.
                          Puedes configurar tu navegador para que rechace las cookies, pero esto podría
                          impedirte realizar ciertas operaciones, usar determinadas funcionalidades o
                          tener acceso a algunos contenidos de nuestra web. 
                        </p>
                          <br>
                          <p>
                          Puedes consultar los pasos
                          para deshabilitar la instalación de cookies en las secciones de "Ayuda" de las
                          páginas web cada navegador, por ejemplo Microsoft Internet Explorer, Google
                          Chrome, Safari, Mozilla Firefox y Opera. 
                        </p>
                          <br>
                          <p class="sep-paragraph">
                            NOTA: Confia no es titular ni
                            patrocina los enlaces indicados anteriormente, por lo que no asume
                            responsabilidad alguna sobre su contenido ni sobre su actualización.
                          </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-landing></app-footer-landing>
