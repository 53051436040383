import { PeriodicElement } from './../../../core/models/cookies/cookiesTable';
import { Component, OnInit } from '@angular/core';


const ELEMENT_DATA: PeriodicElement[] = [
  {
    type: "Datos sesión del usuario", 
    info: 'Sesión, registro, datos de compra de participaciones, datos para distinguir los servicios de pago.', 
    use: "Son temporales y desaparecen cuando finaliza la sesión. Ayudan a analizar pautas de tráfico en la web para adecuar a ellas los servicios de Confia.", 
  },
  {
    type: "Técnicas y funcionales", 
    info: "Un identificador de usuario por sesión y los parámetros necesarios para navegar en la web.", 
    use: "Son estrictamente necesarias para navegar por la web de Confia y utilizar sus diferentes opciones o servicios. Nunca se utilizarán para recopilar información con el objetivo de mostrarte publicidad personalizada.", 
  },  
];
const ELEMENT_DATATWO: PeriodicElement[] = [
  {
    type: "De análisis (Google Analytics)", 
    info: 'Sesión, registro, datos de compra. Almacena información como, por ejemplo, la hora de la visita, si el usuario ha estado antes en el sitio y qué sitio ha referenciado al usuario al portal de Confia. de participaciones, datos para distinguir los servicios de pago.', 
    use: "Realiza un seguimiento de las interacciones de los usuarios en el sitio web y genera información estadística.",
    business:"Google" 
  },
  {
    type: "De marketing (Google Display)", 
    info: 'Asociado al servicio de Google Analytics', 
    use: "Información estadística sobre los usuarios proporcionada por Google.",
    business:"Google" 
  },
  {
    type: "De usabilidad (Google reCaptcha)", 
    info: 'Información de software y hardware, datos de la aplicación y del dispositivo.', 
    use: "Evita el fraude en el sitio web bloqueando los bots.",
    business:"Google" 
  },
   
  
];
@Component({
    selector: 'app-cookies-info',
    templateUrl: './cookies-info.component.html',
    styleUrls: ['./cookies-info.component.scss'],
    standalone: false
})


export class CookiesInfoComponent implements OnInit {
  displayedColumns: string[] = ['type', 'info', 'use'];
  dataSource = ELEMENT_DATA;
  displayedColumnsTwo: string[] = ['type', 'business', 'info', 'use'];
  dataSourceTwo = ELEMENT_DATATWO;
  
  constructor() {
    
  }
  ngOnInit(): void {
  }
}
